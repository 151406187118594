const DialCode = [
  {
    value: "+44",
    label: "+44",
  },
  {
    value: "+1",
    label: "+1",
  },
  {
    value: "+7",
    label: "+7",
  },
  {
    value: "+31",
    label: "+31",
  },
  {
    value: "+32",
    label: "+32",
  },
  {
    value: "+33",
    label: "+33",
  },
  {
    value: "+34",
    label: "+34",
  },
  {
    value: "+39",
    label: "+39",
  },
  {
    value: "+41",
    label: "+41",
  },
  {
    value: "+46",
    label: "+46",
  },
  {
    value: "+48",
    label: "+48",
  },
  {
    value: "+49",
    label: "+49",
  },
  {
    value: "+52",
    label: "+52",
  },
  {
    value: "+55",
    label: "+55",
  },
  {
    value: "+61",
    label: "+61",
  },
  {
    value: "+62",
    label: "+62",
  },
  {
    value: "+66",
    label: "+66",
  },
  {
    value: "+81",
    label: "+81",
  },
  {
    value: "+82",
    label: "+82",
  },
  {
    value: "+86",
    label: "+86",
  },
  {
    value: "+90",
    label: "+90",
  },
  {
    value: "+91",
    label: "+91",
  },
  {
    value: "+234",
    label: "+234",
  },
  {
    value: "+966",
    label: "+966",
  },
];

const countryOptions = [];
// const countryOptions = [
//   {
//     value: "United Kingdom",
//     label: "United Kingdom",
//     inputValue: "United Kingdom",
//   },
//   {
//     value: "Great Britain",
//     label: "Great Britain",
//     inputValue: "Great Britain",
//   },
//   { value: "England", label: "England", inputValue: "England" },
//   {
//     value: "Northern Ireland",
//     label: "Northern Ireland",
//     inputValue: "Northern Ireland",
//   },
//   { value: "Scotland", label: "Scotland", inputValue: "Scotland" },
//   { value: "Wales", label: "Wales", inputValue: "Wales" },
// ];
const DriverOption = [
  {
    value: "Quantity",
    label: "Quantity",
  },
  {
    value: "Variation",
    label: "Variation",
  },
  { value: "Slab Based", label: "Slab  Based" },
];
const SlabOption = [
  {
    value: "Slab Block",
    label: "Slab Block",
  },
  {
    value: "Incremental Slab",
    label: "Incremental Slab",
  },
];
const ServiceCatOption = [
  {
    value: "Taxation",
    label: "Taxation",
  },
  {
    value: "Payroll",
    label: "Payroll",
  },
  {
    value: "Company Secretarial",
    label: "Company Secretarial",
  },
  {
    value: "Support",
    label: "Support",
  },
  {
    value: "Advisory",
    label: "Advisory",
  },
  {
    value: "Tax & Accountancy",
    label: "Tax & Accountancy",
  },
  {
    value: "Bookkeeping",
    label: "Bookkeeping",
  },
];
const ClientType = [
  {
    value: "Individual",
    label: "Individual",
  },
  {
    value: "Sole Trader",
    label: "Sole Trader",
  },
  { value: "Partnership", label: "Partnership" },
  { value: "Llp", label: "Llp" },
  { value: "Company", label: "Company" },
];
const BusinessType = [
  {
    value: "Sole Trader",
    label: "Sole Trader",
  },
  { value: "Partnership", label: "Partnership" },
  { value: "Llp", label: "Llp" },
  { value: "Company", label: "Company" },
];

const VAT_Registered = [
  {
    value: 0,
    label: "Yes",
  },
  {
    value: 1,
    label: "No",
  },
];
const IS_default = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const isActive = [
  {
    value: 0,
    label: "No",
  },
  {
    value: 1,
    label: "Yes",
  },
];
const ElementOption = [
  { value: "Heading", label: "Heading" },
  { value: "Text Block", label: "Text Block" },
  { value: "Service Pricing Table", label: "Service Pricing Table" },
  { value: "Service Description", label: "Service Description" },
  { value: "Full Page Heading", label: "Full Page Heading" },
  { value: "Page Break", label: "Page Break" },
  { value: "Signature Block", label: "Signature Block" },
  { value: "Statement Of Fact", label: "Statement Of Fact" },
];
const TemplateOption = [
  { value: 1, label: "Quote" },
  { value: 2, label: "Contract" },
];
const ProposalFormat = [
  { value: "HTML", label: "HTML" },
  { value: "PDF", label: "PDF" },
];
const EntityType = [
  { value: "Ltd Company", label: "Ltd Company" },
  { value: "Sole Trader", label: "Sole Trader" },
  { value: "Partnership", label: "Partnership" },
  { value: "LLP", label: "LLP" },
  { value: "Other", label: "Other" },
];

const NatureOfBusiness = [
  {
    value: "Professional/IT Services",
    label: "Professional/IT Services",
  },
  {
    value: "Restaurant/Hotel/Bar/Travel",
    label: "Restaurant/Hotel/Bar/Travel",
  },
  {
    value: "Construction/Development",
    label: "Construction/Development",
  },
  {
    value: "Rental",
    label: "Rental",
  },
  {
    value: "Home Services/Construction Trades",
    label: "Home Services/Construction Trades",
  },
  {
    value: "E-commerce/Retailers/Trading",
    label: "E-commerce/Retailers/Trading",
  },
  {
    value: "Other",
    label: "Other",
  },
];
const WageMonth = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
const Pension = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
const PayrollFreq = [
  {
    value: "Weekly",
    label: "Weekly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
  {
    value: "Weekly & Monthly",
    label: "Weekly & Monthly",
  },
  {
    value: "Quarterly",
    label: "Quarterly",
  },
  {
    value: "Yearly",
    label: "Yearly",
  },
];
const CISfreq = [
  {
    value: "Weekly",
    label: "Weekly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
  {
    value: "Weekly & Monthly",
    label: "Weekly & Monthly",
  },
  {
    value: "Quarterly",
    label: "Quarterly",
  },
  {
    value: "Yearly",
    label: "Yearly",
  },
];
const CalType = [
  {
    value: "Simple",
    label: "Simple",
  },
  {
    value: "Detailed",
    label: "Detailed",
  },
];

const Incorporated_In = [
  {
    value: "England and Wales (EW)",
    label: "England and Wales (EW)",
  },
  {
    value: "Northern Ireland(NI)",
    label: "Northern Ireland(NI)",
  },
  {
    value: "Scotland (SC)",
    label: "Scotland (SC)",
  },
  {
    value: "Wales (WA)",
    label: "Wales (WA)",
  },
];

const UserRole = [
  {
    value: "Admin",
    label: "Admin",
  },
];

const Payment_Frequency = [
  {
    value: 1,
    label: "Yearly",
  },
  {
    value: 2,
    label: "Half Yearly",
  },
  {
    value: 3,
    label: "Quarterly",
  },
  {
    value: 4,
    label: "Monthly",
  },
];

const EngagementLetter = [
  {
    Ref_ID: "OB-1104",
    Proposals: "test",
    Status: "Sent",
    Value1: "Recurring: £677,381.00",
    Value2: "One-Off: NA",
    Documents: "Quotation PDF",
  },
  {
    Ref_ID: "OB-1103",
    Proposals: "Shaurya Pharma",
    Status: "Sent",
    Value1: "Recurring: £11,202.00",
    Value2: "One-Off: NA",
    Documents: "Quotation PDF",
  },
  {
    Ref_ID: "OB-1102",
    Proposals: "fsdf af",
    Status: "Draft",
    Value1: "",
    Value2: "",
    Documents: "",
  },
  {
    Ref_ID: "OB-1101",
    Proposals: "	demo demo",
    Status: "Skipped",
    Value1: "Recurring: £100.00",
    Value2: "One-Off: £0.00",
    Documents: "Quotation PDF",
  },
  {
    Ref_ID: "OB-1098",
    Proposals: "LON CONSULTING LIMITED",
    Status: "Sent",
    Value1: "Recurring: £29.17",
    Value2: "One-Off: NA",
    Documents: "Quotation PDF",
  },
  {
    Ref_ID: "OB-1104",
    Proposals: "test",
    Status: "Sent",
    Value1: "Recurring: £677,381.00",
    Value2: "One-Off: NA",
    Documents: "Quotation PDF",
  },
  {
    Ref_ID: "OB-1104",
    Proposals: "test",
    Status: "Sent",
    Value1: "Recurring: £677,381.00",
    Value2: "One-Off: NA",
    Documents: "Quotation PDF",
  },
  {
    Ref_ID: "OB-1104",
    Proposals: "test",
    Status: "Sent",
    Value1: "Recurring: £677,381.00",
    Value2: "One-Off: NA",
    Documents: "Quotation PDF",
  },
];

const select_client = [
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "test",
    label: "test",
  },
  {
    value: "Abc",
    label: "Abc",
  },
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "Superadmin",
    label: "Superadmin",
  },
];

const select_Template = [
  {
    value: "Default Partnership Quote Template",
    label: "Default Partnership Quote Template",
  },
  {
    value: "Customised Partnership Quote Template",
    label: "Customised Partnership Quote Template",
  },
];

const selectServiceChargesType = [
  {
    value: "Recurring",
    label: "Recurring",
  },
  {
    value: "One Off",
    label: "One Off",
  },
  {
    value: "Both Recurring & One Off",
    label: "Both Recurring & One Off",
  },
];

const BookkeepingTransactions = [
  {
    value: "20",
    label: "20",
  },
  {
    value: "50",
    label: "50",
  },
  {
    value: "100",
    label: "100",
  },
];

const Select_Entity_Type = [
  {
    value: "Ltd Company",
    label: "Ltd Company",
  },
  {
    value: "Sole Trader",
    label: "Sole Trader",
  },
  {
    value: "Partnership",
    label: "Custom (Single)",
  },
];

const PricingType = [
  {
    value: "Fixed Price",
    label: "Fixed Price",
  },
  {
    value: "Formula Based Pricing",
    label: "Formula Based Pricing",
  },
];
const paymentStatus = [
  {
    value: null,
    label: "All",
  },
  {
    value: 1,
    label: "Paid",
  },
  {
    value: 0,
    label: "Unpaid",
  },
];

const source = [
  {
    value: 1,
    label: "From Scratch",
  },
  {
    value: 2,
    label: "From Quote",
  },
  // {
  //   isDisabled: false,
  //   value: 3,
  //   label: "Package (Customisable) (Single)",
  // },
  // {
  //   isDisabled: false,
  //   value: 4,
  //   label: "Packaged (Standard) (Single)",
  // },
];
const template = [
  {
    value: "Default LLP Contract Template",
    label: "Default LLP Contract Template",
  },
];

const tnc_Template = [
  {
    value: "Sole Trader Terms & Conditions Template",
    label: "Sole Trader Terms & Conditions Template",
  },
];
const payment_gateway = [
  // {
  //   value: 1,
  //   label: "Not Applicable",
  // },
  {
    value: 1,
    label: "Manual",
  },
  {
    value: 2,
    label: "Stripe",
  },
  {
    value: 3,
    label: "Go Cardless",
  },
  // {
  //   value: 4,
  //   label: "Bank Transfer",
  // },
];
const fees = [
  {
    value: "ManuaShow Full Breakdownl",
    label: "Show Full Breakdown",
  },
  {
    value: "Show Totals Only",
    label: "Show Totals Only",
  },
];

const Yearly_Turnover = [
  {
    value: "0-€20k",
    label: "0-€20k",
  },
  {
    value: "€20.1-€85k",
    label: "€20.1-€85k",
  },
  {
    value: "€85.1-€120k",
    label: "€85.1-€120k",
  },
];

const select_Quote_Type = [
  {
    value: 1,
    label: "Package (Customisable) (Single/Multiple)",
  },
  {
    value: 2,
    label: "Packaged (Standard) (Single/Multiple)",
  },
  {
    value: 3,
    label: "Custom (Single)",
  },
];

const ProposalStatus = [
  {
    value: null,
    label: "All",
  },
  {
    value: 2,
    label: "Sent (Awaiting Response + Accepted + Declined)",
  },

  {
    value: 1,
    label: "Draft",
  },
  {
    value: 4,
    label: "Awaiting Response",
  },

  // {
  //   value: 5,
  //   label: "Signed",
  // },
  {
    value: 6,
    label: "Accepted",
  },
  {
    value: 7,
    label: "Declined",
  },
  {
    value: 3,
    label: "Skipped",
  },
];

const EngagementLetterStatus = [
  {
    value: null,
    label: "All",
  },
  {
    value: 2,
    label: "Sent (Viewed + Signed + Declined)",
  },

  {
    value: 1,
    label: "Draft",
  },
  {
    value: 4,
    label: "Viewed",
  },

  {
    value: 5,
    label: "Signed",
  },
  // {
  //   value: 6,
  //   label: "Accepted",
  // },
  {
    value: 7,
    label: "Declined",
  },
  // {
  //   value: 3,
  //   label: "Skipped",
  // },
];

const PreviewSelection = [
  {
    value: 1,
    label: "HTML",
  },
  {
    value: 2,
    label: "PDF",
  },
];
const CalenderFilter = [
  {
    value: 0,
    label: "All",
  },
  {
    value: 1,
    label: "This Week",
  },
  {
    value: 2,
    label: "Last Week",
  },
  {
    value: 3,
    label: "This Month",
  },
  {
    value: 4,
    label: "Last Month",
  },
  {
    value: 5,
    label: "This Quarter",
  },
  {
    value: 6,
    label: "Last Quarter",
  },
  {
    value: 7,
    label: "This 6 Months",
  },
  {
    value: 8,
    label: "Last 6 Months",
  },
  {
    value: 9,
    label: "This Year",
  },
  {
    value: 10,
    label: "Last Year",
  },
  {
    value: 11,
    label: "Custom Date Range",
  },
];
const ucFirst = (word) => word.charAt(0).toUpperCase() + word.slice(1); // Upper Case First Letter
const stringifyNumber = (n) => {
  const special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
  ];
  const deca = [
    "twent",
    "thirt",
    "fort",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet",
  ];
  const bigs = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
  ]; // Add more as needed
  let numStr = "";

  const chunkify = (num) => {
    const chunks = [];
    while (num > 0) {
      chunks.push(num % 1000);
      num = Math.floor(num / 1000);
    }
    return chunks;
  };

  const chunkToWords = (chunk) => {
    if (chunk === 0) return "";
    let words = "";
    const hundreds = Math.floor(chunk / 100);
    const tens = chunk % 100;
    if (hundreds > 0) {
      words += special[hundreds] + " hundred";
      if (tens > 0) words += " and ";
    }
    if (tens < 20) {
      words += special[tens];
    } else {
      const tensIndex = Math.floor(tens / 10) - 2;
      words += deca[tensIndex];
      if (tens % 10 !== 0) words += "y-" + special[tens % 10];
    }
    return words;
  };

  const chunks = chunkify(n);
  const wordsArray = chunks.map((chunk, index) => {
    const bigName = bigs[index];
    const chunkWords = chunkToWords(chunk);
    return chunkWords + (chunkWords && bigName ? " " + bigName : "");
  });

  numStr = wordsArray.reverse().join(" ");

  return ucFirst(numStr);
};

const QuoteIndividualSoleTrader = [
  "$Accountant.SoleTrader.FirstName$",
  "$Accountant.SoleTrader.LastName$",
  "$Accountant.SoleTrader.Email$",
  "$Accountant.SoleTrader.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.SoleTrader.Address$",
  "$Accountant.SoleTrader.AddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$Client.FirstName$",
  "$Client.LastName$",
  "$Client.Email$",
  "$Client.Phone$",
  "$Client.Address$",
  "$Client.AddressWithLineBreak$",
];

const QuoteIndividualPartnership = [
  "$Accountant.Partner.FirstName$",
  "$Accountant.Partner.LastName$",
  "$Accountant.Partner.Email$",
  "$Accountant.Partner.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.Partner.Address$",
  "$Accountant.Partner.AddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$Client.FirstName$",
  "$Client.LastName$",
  "$Client.Phone$",
  "$Client.Address$",
  "$Client.AddressWithLineBreak$",
];

const QuoteIndividualLlp = [
  "$Accountant.Officer.FirstName$",
  "$Accountant.Officer.LastName$",
  "$Accountant.Officer.Email$",
  "$Accountant.Officer.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.Company.Name$",
  "$Accountant.Company.Number$",
  "$Accountant.Company.RegisteredAddress$",
  "$Accountant.Company.RegisteredAddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$Client.FirstName$",
  "$Client.LastName$",
  "$Client.Email$",
  "$Client.Phone$",
  "$Client.Address$",
  "$Client.AddressWithLineBreak$",
];
const QuoteIndividualCompany = [
  "$Accountant.Officer.FirstName$",
  "$Accountant.Officer.LastName$",
  "$Accountant.Officer.Email$",
  "$Accountant.Officer.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.Company.Name$",
  "$Accountant.Company.Number$",
  "$Accountant.Company.RegisteredAddress$",
  "$Accountant.Company.RegisteredAddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$Client.FirstName$",
  "$Client.LastName$",
  "$Client.Email$",
  "$Client.Phone$",
  "$Client.Address$",
  "$Client.AddressWithLineBreak$",
];

const feeInProposal = [
  {
    value: 1,
    label: "Show Full Breakdown",
  },
  {
    value: 2,
    label: "Show Totals Only",
  },
];

const DiscountLines = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 2,
    label: "No",
  },
];
const SignaturePosition = [
  {
    value: 1,
    label: "Right Column",
  },
  {
    value: 2,
    label: "Left Column",
  },
];

const TemplateTypeLookupListSuperAdmin = [
  {
    value: 1,
    label: "Confirmation Email",
  },
  {
    value: 2,
    label: "Welcome Email",
  },
  {
    value: 3,
    label: "Forget Password Email",
  },
  {
    value: 4,
    label: "Invite For Sign-up Email",
  },
  {
    value: 5,
    label: "2-FA Email",
  },
];

const dropdownOptions = [
  { id: 0, value: "Select..." },
  { id: 1, value: "BT Internet" },
  { id: 2, value: "Gmail" },
  { id: 3, value: "Hotmail" },
  { id: 4, value: "Microsoft" },
  { id: 5, value: "Microsoft Exchange Server" },
  { id: 6, value: "Office 365" },
  { id: 7, value: "Yahoo" },
  { id: 8, value: "Other" },
  // { id: null, value: "Other" }
];

const emailTemplates = [
  { value: 1, label: "Template 1" },
  { value: 2, label: "Template 2" },
  { value: 3, label: "Template 3" },
];

const periods = [{ value: 1, label: "After" }];

const repeats = [
  { value: 1, label: "Yes" },
  { value: 2, label: "No" },
];

export default {
  emailTemplates,
  periods,
  repeats,
  stringifyNumber,
  SignaturePosition,
  QuoteIndividualSoleTrader,
  QuoteIndividualPartnership,
  QuoteIndividualLlp,
  QuoteIndividualCompany,
  isActive,
  feeInProposal,
  ProposalStatus,
  CalenderFilter,
  Yearly_Turnover,
  BookkeepingTransactions,
  DialCode,
  countryOptions,
  DriverOption,
  ClientType,
  VAT_Registered,
  EntityType,
  IS_default,
  NatureOfBusiness,
  WageMonth,
  Pension,
  PayrollFreq,
  CISfreq,
  EngagementLetterStatus,
  CalType,
  ElementOption,
  TemplateOption,
  Incorporated_In,
  UserRole,
  SlabOption,
  source,
  EngagementLetter,
  select_client,
  select_Template,
  select_Quote_Type,
  ServiceCatOption,
  selectServiceChargesType,
  PricingType,
  Payment_Frequency,
  ProposalFormat,
  paymentStatus,
  template,
  PreviewSelection,
  TemplateTypeLookupListSuperAdmin,
  tnc_Template,
  payment_gateway,
  fees,
  BusinessType,
  DiscountLines,
  dropdownOptions,
};
